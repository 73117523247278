import React from "react"

import FeaturedPromotionCard from "~/ui/components/promotion/featured"
import TitledSection from "~/ui/layout/titled-section"

type ItemProps = {
  type: string
  title: string
  image: string
  button: {
    label: string
    link: string
  }
}

type Props = {
  title?: string
  items: any
}

function PromotionList({ title, items }: Props) {
  return (
    <TitledSection title={title} background="white">
      <div className="grid grid-cols-1 gap-6">
        {items.map((item: ItemProps, index: number) => {
          return (
            <FeaturedPromotionCard
              key={index}
              type={item.type}
              image={item.image}
              title={item.title}
              button={item.button}
            />
          )
        })}
      </div>
    </TitledSection>
  )
}

export default PromotionList
