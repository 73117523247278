import React from "react"
import cx from "classnames"

import TitledSection from "~/ui/layout/titled-section"
import sanitize from "~/helpers/sanitize"

type Props = {
  className?: string
  title: string
  description: string
  items: string[]
}

function Checklist({ className, title, description, items }: Props) {
  return (
    <TitledSection
      title={title}
      description={description}
      className={cx(className)}
    >
      <div className="flex-1">
        <ul>
          {items.map((item, index) => (
            <li
              key={index}
              className="bg-blue-200 flex gap-4 items-start mb-4 lg:mb-6 last:mb-0 rounded-3xl p-4 lg:p-[26px]"
            >
              <div className="font-bold text-white flex-col text-center h-6 w-6 lg:h-8 lg:w-8 flex-shrink-0 flex justify-center bg-blue-600 rounded-full">
                {index + 1}
              </div>
              <p
                className="text-font-1"
                dangerouslySetInnerHTML={sanitize(item)}
              ></p>
            </li>
          ))}
        </ul>
      </div>
    </TitledSection>
  )
}

export default Checklist
