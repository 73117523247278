import { ContentfulBonusCollection } from "../../types/graphql-types"

import Bonus from "./bonus"

export default class BonusCollection {
  id: string
  type: string
  bonuses: Bonus[]

  constructor(collection: ContentfulBonusCollection) {
    this.id = collection.id
    this.type = collection.type || ""

    this.bonuses = collection.bonuses
      ? collection.bonuses!.map((bonus: any) => new Bonus(bonus))
      : []
  }
}
