import React from "react"

import BonusCollection from "~/models/bonus-collection"
import Bonus from "~/models/bonus"
import BonusCard from "~/ui/components/bonus/card"
import TitledSection from "~/ui/layout/titled-section"

type Props = {
  title?: string
  description?: string
  background?: string
  collection: BonusCollection
}

function BonusList({ title, description, background, collection }: Props) {
  return (
    <TitledSection
      title={title}
      description={description}
      background={background}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {collection.bonuses.map((bonus: Bonus) => {
          return <BonusCard key={bonus.id} bonus={bonus} />
        })}
      </div>
    </TitledSection>
  )
}

export default BonusList
