import React from "react"

import LocalImage from "~/ui/elements/image/local"
import TitledSection from "~/ui/layout/titled-section"
import sanitize from "~/helpers/sanitize"

type Props = {
  title: string
  description?: string
  items: string[]
  background: string
  image?: string
}

function Paragraphs({ title, description, items, background, image }: Props) {
  return (
    <TitledSection
      title={title}
      description={description}
      background={background as any}
    >
      <React.Fragment>
        {items && (
          <div className="">
            {items.map((item, index) => {
              return (
                <p
                  key={index}
                  className="text-lg text-font-1 mt-4"
                  dangerouslySetInnerHTML={sanitize(item)}
                ></p>
              )
            })}
          </div>
        )}

        {image && (
          <LocalImage
            className="max-w-[738px] mx-auto rounded-3xl mt-4 lg:mt-12"
            filename={`${image}`}
            alt={title}
          />
        )}
      </React.Fragment>
    </TitledSection>
  )
}

export default Paragraphs
