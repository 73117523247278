import React from "react"
import cx from "classnames"

import Bonus from "~/models/bonus"
import Image from "~/ui/elements/image/image"
import Info from "~/ui/identity/icon/info"
import PartnerLink from "~/ui/elements/button/partner-link"
import useBonusPopup from "~/hooks/useBonusPopup"

type Props = {
  bonus: Bonus
  className?: string
}

function BonusCard({ bonus, className }: Props) {
  const { handleActivation } = useBonusPopup()

  function onClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    handleActivation(bonus, e)
  }

  return (
    <div
      className={cx("relative flex flex-col shadow-card rounded-lg", className)}
    >
      <div className="flex justify-center py-10 border-b border-purple-100">
        <div className="overflow-hidden rounded-lg">
          <Image
            className="block w-[80px]"
            gatsbyImageData={bonus.casino.logo}
            alt={bonus.casino.name}
            zoom
          />
        </div>
      </div>

      <div className="p-4 flex flex-col flex-1 justify-between">
        <div className="mb-1 text-sm text-purple-300">
          {bonus.type.toUpperCase()}
        </div>
        <div className="mb-8 text-2xl font-medium text-font-1">{`${bonus.casino.name}: ${bonus.value} ${bonus.matchShare} bonus`}</div>
        <div className="flex items-center gap-1 mb-2">
          <span className="text-sm text-font-2">Bonus Info</span>
          <button onClick={e => onClick(e)}>
            <Info width={16} />
          </button>
        </div>
        <PartnerLink type="primary" casino={bonus.casino}>
          Get Bonus
        </PartnerLink>
      </div>
    </div>
  )
}

export default BonusCard
