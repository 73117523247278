import React from "react"
import cx from "classnames"

import Link from "~/ui/elements/button/link"

type Props = {
  type: string
  title: string
  image: {
    path: string
    alt: string
  }
  button: {
    label: string
    link: string
  }
  className?: string
}

function FeaturedPromotionCard({
  type,
  title,
  button,
  image,
  alt,
  className,
}: Props) {
  return (
    <div
      className={cx(
        "flex flex-col lg:flex-row rounded-lg p-4 lg:p-10 bg-font-1 gap-6",
        className
      )}
    >
      <div className="flex-1">
        <img
          src={`/images/general/logo-dark.svg`}
          alt=""
          className="w-[140px] mb-6"
          decoding="async"
        />
        <p className="text-lg lg:text-2xl text-medium text-white text-center lg:text-left">
          {title}
        </p>
      </div>

      <div className="flex flex-col items-center rounded-lg p-4 bg-white">
        <img
          src={`/images/${image.path}`}
          alt={image.alt}
          className="block w-[120px] mb-6"
          decoding="async"
        />
        <div className="mb-4 text-lg text-font-2">{type}</div>

        <a
          href={button.link}
          target="_blank"
          rel="noopener nofollow"
          className={cx(
            "block font-medium py-3 px-9 leading-none text-center text-lg rounded-lg bg-blue-600 text-white hover:bg-blue-700",
            className
          )}
        >
          {button.label}
        </a>
      </div>
    </div>
  )
}

export default FeaturedPromotionCard
