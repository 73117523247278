import React from "react"
import cx from "classnames"

type Props = {
  type: string
  body: string
  alt: string
  background: string
  image: {
    path: string
    alt: string
  }
  button: {
    label: string
    link: string
  }
  className?: string
}

function PromotionCard({
  type,
  body,
  button,
  image,
  className,
}: Props) {
  return (
    <div
      className={cx(
        "relative overflow-hidden rounded-lg p-4 bg-yellow-300",
        className
      )}
    >
      <img
        src="/images/general/mandala-yellow-1.svg"
        alt=""
        className="w-[300px] lg:w-[520px] absolute -bottom-[36px] -left-[92px] lg:-top-[15px] lg:-left-[380px]"
        decoding="async"
      />

      <div className="relative z-10 mb-6 flex justify-end h-[60px]">
        <img
          src={`/images/${image.path}`}
          alt={image.alt}
          className="w-[82px]"
          decoding="async"
        />
      </div>

      <div className="relative z-10 mb-1 text-font-1">{type}</div>

      <p className="relative z-10 mb-8 text-font-1 text-xl lg:text-2xl font-medium">
        {body}
      </p>

      <a
        href={button.link}
        target="_blank"
        rel="noopener nofollow"
        className="relative z-10 block py-4 px-12 text-base leading-none text-center lg:text-lg rounded-lg bg-blue-600 text-white hover:bg-blue-700"
      >
        {button.label}
      </a>
    </div>
  )
}

export default PromotionCard
